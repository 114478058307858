/*# sourceMappingURL=custom.min.css.map */
#scrollbar::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0);
}

#scrollbar::-webkit-scrollbar-thumb {
    background-color: #a4c7ff;
}

#scrollbar::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: rgba(0, 0, 0, 0);
}

ul li a label {
    background: #fff;
    color: #111;
    font-weight: bold;
    border-radius: 17px;
    padding: 2px 7px;
    margin-left: 7px;
}

.designBy {
    text-align: center;
    font-size: 12px;
    background: #222;
    padding: 3px;

    label {
        color: #a4c7ff;

        a {
            color: #f8c905;
            font-weight: 700;
        }
        a:hover {
            color: #ffdd56;
        }
    }
}