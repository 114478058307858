.badge-warning {
    color: #fff;
    background-color: #f6c23e;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-secondary {
    color: #fff;
    background-color: #858796;
}

.badge-primary {
    color: #fff;
    background-color: #4e73df;
}

.badge-info {
    color: #fff;
    background-color: #36b9cc;
}

.badge-success {
    color: #fff;
    background-color: #1cc88a;
}