.package-poit {
    border: 2px solid #405189;
    text-align: center;
    margin: 0px;

    h3 {
        font-weight: bold;
    }

    .padding-bot-7 {
        padding-bottom: 7px !important;
    }

    .box-item {
        padding: 19px 9px;

        h3 {
            color: #405189 !important;
            font-size: 22px;
            margin: 12px 0px;
            font-weight: bold;
        }

        .line {
            width: 2px;
            height: 100%;
            border-right: 3px dotted #405189;
            position: absolute;
            top: 0px;
            right: 0;
            height: 80%;
            margin-top: 10%;
        }

        color: #405189;

        p {
            font-size: 19px;
            padding-bottom: 5px;
            margin-bottom: 0px;
            color: #111;
        }

        p.red {
            color: red;
            line-height: 19px;
            font-weight: bold;
            padding-bottom: 0px;
            font-style: italic;
        }
    }

    .box {
        background-color: #405189;
        margin-left: -2px;

        p {
            color: #FFF;
        }

        h3 {
            color: #FFF !important;
            font-size: 27px;
        }
    }
}