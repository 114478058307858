/*
 * General styles
 */
body,
html {
  background-image: "#fff";
  background-repeat: no-repeat;
  background-size: cover;
  font-family: $fontmain;
  font-size: $base-size - 2px;
  height: 100vh;
  overflow: hidden;
}

.btn {
  // padding: 0;
  // border: none;
  // cursor: pointer;
  // font-weight: 700;
  // height: 30px;
  // line-height: 30px;
  // -moz-user-select: none;
  // -webkit-user-select: none;
  // user-select: none;

  i {
    font-size: $base-size - 3px;
  }
}

.btn-edit,
.btn-add,
.btn-delete,
.btn-assign {
  background-color: $colormain;
  color: $common-text-color;

  &:hover {
    color: $common-text-color;
    background-color: darken($colormain, 5);
  }
}

#password:invalid {
  font-family: $fontmain; // Fixed TH hien thi placeholder cho EDGE
}

#password:-webkit-input-placeholder {
  font-family: $fontmain; // CHROME 1
}

#password::-webkit-input-placeholder {
  font-family: $fontmain; //CHROME 2   EDGE 1
}

#password:-moz-placeholder {
  font-family: $fontmain; // Firefox 4-18
}

#password::-moz-placeholder {
  font-family: $fontmain; // Firefox 19+
}

#password:-ms-input-placeholder {
  font-family: $fontmain; // IE 10-11
}

#password::-ms-input-placeholder {
  font-family: $fontmain; // EDGE 2
}

#password::placeholder {
  font-family: $fontmain;
}

.content-container {
  .title {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $base-size + 4px;
    margin-top: 15px;
    color: $colormain;
  }

  .content {
    margin: 15px 30px;
    box-shadow: 2px 2px 4px 0 $box-shadow-color;
    -webkit-box-shadow: 2px 2px 4px 0 $box-shadow-color;
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: 0 10px;
        height: 35px;
        background-color: $colormain;
        color: $common-text-color;

        .modal-title {
          line-height: 35px;
          font-weight: 500;
          font-size: $base-size + 1px;
        }

        .btn-close {
          position: absolute;
          color: $common-text-color;

          i {
            line-height: 35px;
            font-size: $base-size + 2px;
          }
        }
      }
    }
  }
}

.separate {
  z-index: 99;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.btn-bs {
  border-radius: 7px;
  padding: 9px 10px;
  width: 100%;
}

.login-container {
  input {
    border: 1px solid #C6C6C6;
    border-radius: 7px;
    padding: 6px 10px;
    width: 100%;
    margin-bottom: 15px;
  }

  input:focus-visible {
    outline: none;
    border: 1px solid $input-color;
  }
}

.cursor {
  cursor: pointer;
}

.register-container {
  input {
    border: 1px solid #C6C6C6;
    border-radius: 7px;
    padding: 6px 10px;
    width: 100%;
    margin-bottom: 15px;
  }

  input:focus-visible {
    outline: none;
    border: 1px solid $input-color;
  }
}

.btn-user {
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0px 34px;
  height: 40px;
}

/// input
input {
  border: 1px solid #C6C6C6;
  border-radius: 7px;
  // padding: 6px 10px;
  width: 100%;
  margin-bottom: 15px;
}

input:focus-visible {
  outline: none;
  border: 1px solid $input-color;
}

.hide {
    display: none;
}
.show {
    display: block;
}
a {
  cursor: pointer;
}
.btn:focus{
  box-shadow: none;
}

#root {
  overflow: auto;
    height: 100vh;
}
body {
  background-color: var(--vz-body-bg);
}
.btn-social {
  button {
    margin: 5px !important;
  }
}
.rnc__notification-container--top-right {
  right: 290px !important;
  // top: -90px !important;
}
.notify-login {
  .rnc__notification-container--top-right {
    right: 20px !important;
    top: 20px !important;
  }
}