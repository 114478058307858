@import "../../styles/common.scss";

.user-manage {
    background: #F5F5F5;
    overflow: auto;
    height: 100vh;

    .container {
        // height: 100vh;
    }
}

.user-header {
    padding-top: 50px;
    padding-bottom: 20px;
}

.user-top {
    margin-bottom: 20px;
    padding-top: 10px;
    width: 100%;
    height: 34px;

    img {
        padding: 7px;
        border-radius: 7px;
        margin-right: 15px;
        background: rgba(255, 136, 27, 0.55);
        width: 38px;
        height: 38px;
    }
}

.user-content {
    margin-bottom: 200px;
    background: white;
}

.user-img {
    text-align: center;
}

.right {
    float: right;
}

.user-detail {
    margin-top: 30px;

    label {
        color: #837F7E;
        padding-bottom: 5px;
    }

    input {
        border-right: none;
        outline: none;
        border-bottom: 1px solid #837F7E;
        border-top: none;
        border-left: none;
        width: 65%;
        margin-bottom: 40px;
        padding: 5px;
        font-size: 1.5rem;
        border-radius: 0px !important;
    }

    h4 {
        margin-bottom: 40px;
        padding: 5px;
    }
}


.user-bottom {
    margin-bottom: 50px;
}
.btn-orange {
  background: $input-color;
  margin-right: 50px;
}

.btn-gray {
  background-color: #B1B1B1;
}