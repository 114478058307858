@import "../../styles/common.scss";

.register-container {
    // margin: 0% 5% 0% 10%;
}

.register-header {
    color: black;
    height: 50px;
    padding-top: 2%;
}

.register-background {
    background-image: -webkit-linear-gradient(113deg, rgba(200, 216, 255, 0.3) 53%, #fafafa 40%);
}

.translate {
    margin-right: 20vh;

    .text {
        padding-left: 6px;
        font-weight: bold;
    }
}

.register-content {
    color: black;
    height: 100vh;
    margin-top: 74px;
}

.register-left {
    width: 25%;
    text-align: center;
}

.register-right {
    width: 75%;
}

.btn-gg {
    width: 100%;
    border-radius: 7px;
    border: 1px solid $input-color;
    color: $input-color;
    background: white;
    margin: 20px 0px;
    padding: 6px 32px;
    cursor: pointer;
    img {
        position: absolute;
        left: 4.3%;
        width: 24px;
        height: 20px;
    }
}
.cb-remember{
    padding: 0px 10px 3px 0px !important;
    width: 17px !important;
    height: 15px;
}
.register-or {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    margin-bottom: 20px;
}

.register-or:after,
.register-or:before {
    content: '';
    width: 34px;
    height: 6px;
    background: #585858;
    margin: 0 7px;
    border-radius: 3px;
}

.input-register {
    margin-top: 60px;
    position: relative;
    input {
        border: 1px solid #C6C6C6;
        margin-bottom: 15px;
    }
}

.forgot {
    margin-bottom: 15px;
    text-align: left;
}

.forgot-login {
    color: $input-color;
}
#remember-cb{
    accent-color: #e74c3c;
}
.btn-register {
    color: white;
    background: $input-color;
    border: 1px solid $input-color;
}

.register-content {
    .register-right {
        background: url('../../assets/images/background/logo_bg.png');
    }
}

// .fa-eye{
//     right: 13px;
//     position: absolute;
//     bottom: 25px;
//     cursor: pointer;
// }
// .fa-eye-slash {
//     right: 13px;
//     position: absolute;
//     bottom: 25px;
//     cursor: pointer;
// }
.captcha {
    display: flex;
}