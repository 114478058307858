.payment {
    p {
        margin-top: 17px;
        font-size: 17px;
    }

    ul li label {
        color: red;
        font-weight: bold;
    }

    .comtent {
        .center {
            text-align: center;
        }

        label {
            display: block;
            text-align: center;
            font-size: 19px;
            font-weight: bold;
            line-height: 19px;
        }

        label.name {
            color: #405189;
            margin-top: 12px;
        }

        img {
            width: 79%;
            margin-top: 12px;
        }
    }
}