@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:ital@0;1&display=swap');

@import "variables";
// @import "../../node_modules/bootstrap/scss/bootstrap";

$fa-font-path : "~@fortawesome/fontawesome-free-webfonts/webfonts";
@import "~@fortawesome/fontawesome-free-webfonts/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free-webfonts/scss/fa-solid.scss";
@import "~@fortawesome/fontawesome-free-webfonts/scss/fa-regular.scss";
@import "~@fortawesome/fontawesome-free-webfonts/scss/fa-brands.scss";

@import "base";
@import "form";