@import "../../styles/common.scss";

.login-container {
    // margin: 0% 5% 0% 10%;
}

.login-header {
    color: black;
    height: 50px;
    padding-top: 2%;
}

.login-background {
    background-image: -webkit-linear-gradient(113deg, rgba(200, 216, 255, 0.3) 53%, #fafafa 40%);
}

.translate {
    margin-right: 20vh;

    .text {
        padding-left: 6px;
        font-weight: bold;
    }
}

.login-content {
    color: black;
    height: 100vh;
    margin-top: 74px;
}

.login-left {
    width: 25%;
    text-align: center;
}

.login-right {
    width: 75%;
}

.btn-gg {
    width: 100%;
    border-radius: 7px !important;
    border: 1px solid $input-color !important;
    color: $input-color !important;
    background: white !important;
    margin: 20px 0px !important;
    padding: 0px 32px !important;
    display: grid !important;
    cursor: pointer;

    img {
        position: absolute;
        left: 4.3%;
        width: 24px;
        height: 20px;
    }
}

.cb-remember {
    padding: 0px 10px 3px 0px !important;
    width: 17px !important;
    height: 15px;
}

.login-or {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    margin-bottom: 20px;
}

.login-or:after,
.login-or:before {
    content: '';
    width: 34px;
    height: 6px;
    background: #585858;
    margin: 0 7px;
    border-radius: 3px;
}

.input-login {
    position: relative;

    input {
        width: 100%;
        border: 1px solid #C6C6C6;
        margin-bottom: 15px;
        height: 34px;
        border-radius: 5px;
        padding: 7px;
    }
}

.forgot {
    margin-bottom: 15px;
}

.forgot-left {
    color: $input-color;
}

#remember-cb {
    accent-color: #e74c3c;
}

.btn-login {
    color: white;
    background: $input-color;
    border: 1px solid $input-color;
}

.login-content {
    .login-right {
        background: url('../../assets/images/background/logo_bg.png');
    }
}

// .fa-eye {
//     right: 13px;
//     position: absolute;
//     bottom: 25px;
//     cursor: pointer;
// }

// .fa-eye-slash {
//     right: 13px;
//     position: absolute;
//     bottom: 25px;
//     cursor: pointer;
// }